.App {
    text-align: center;
}
.ant-image-preview-operations-wrapper {
    bottom: 0;
    top: auto;
}
#root {
    height: 100%;
}

.App-header {
    background-image: url('../../assets/img/home-background.png');
    background-position: left top;
    background-size: cover;
    overflow: hidden;
    height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
    position: relative;
    .home-top-wrap {
        width: 100%;
        // padding: 42px 58px 25px 80px;
        display: flex;
        justify-content: space-between;
        height: 167px;
        position: relative;
    }
    .home-top {
        // padding: 42px 58px 25px 80px;
        // width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 80px;
        top: 42px;
        width: calc(100% - 138px);
        z-index: 9;
        height: 100px;
        .home-top-left {
            display: flex;
            align-items: center;
            height: 100px;
        }
        .App-logo {
            width: 106px;
            height: 100px;
        }
        .logo-title {
            width: 492px;
            height: 30px;
            margin-left: 20px;
        }
    }
    .home-weather-icon {
        margin-right: 12px;
    }
    .home-top-right {
        width: 520px;
        height: 100px;
        background-image: url('../../assets/img/weather-bac.png');
        background-position: left top;
        background-size: cover;
        // background-color: rgba(255, 248, 240, 0.5);
        display: flex;
        align-items: center;
        // padding: 0 22px;
        // padding: 0 22px 0 28px;
        position: relative;
        .home-top-right-box {
            width: 50%;
            display: flex;
            align-items: center;
        }
        .home-weather-number {
            font-weight: 400;
            font-size: 24px;
            color: #1d2129;
            text-align: center;
        }
        .home-weather-text {
            font-weight: 400;
            font-size: 16px;
            color: #86909c;
            text-align: center;
            line-height: 24px;
        }
        .home-top-right-line {
            position: absolute;
            width: 2px;
            height: 40px;
            background: #ffffff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .home-weather-time {
            font-weight: bold;
            font-size: 48px;
            color: #1d2129;
            text-align: center;
            margin-left: 16px;
        }
    }
    .home-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 80px 0 100px;
        .home-center-left {
            position: relative;
            .center-circle {
                width: 850px;
                height: 850px;
            }
            .home-center-img {
                position: absolute;
                border: 8px solid #fff;
                border-radius: 50%;
                width: 800px;
                height: 800px;
                left: 137px;
                top: 42px;
                z-index: 9;
            }
        }

        .swiper-video,
        .swiper-image {
            width: 784px;
            height: 784px;
            border-radius: 50%;
        }
        .swiper-video::-webkit-media-controls {
            display: none !important;
        }
        .ant-image-mask {
            border-radius: 50%;
        }
        :not(:root):fullscreen {
            border-radius: 0;
        }
        .home-center-right-wrap {
            width: 660px;
        }
        .home-center-right {
            width: 660px;
            position: relative;
            height: 640px;
            z-index: 99;
        }
        .company-profile {
            width: 660px;
            height: 200px;
            margin-bottom: 20px;
            cursor: pointer;
        }
        .company-introduce-left-img:active,
        .company-profile:active,
        .industry-information:active,
        .company-map-img:active {
            transform: scale(1.05);
        }
        .company-introduce-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 200px;
            margin-bottom: 20px;
        }
        .company-introduce-left {
            width: 320px;
        }
        .company-introduce-left-img {
            width: 320px;
            height: 200px;
            cursor: pointer;
        }
        .company-map-img {
            width: 660px;
            height: 200px;
        }
        .industry-information {
            width: 320px;
            height: 420px;
            cursor: pointer;
        }
    }
    .home-bottom {
        // bottom: 0;
        bottom: -40px;
        left: 0;
        position: absolute;
        width: 100%;
        height: 350px;
        z-index: 10;
    }
    .cloud-top {
        top: 150px;
        // left: 0;
        position: absolute;
        width: 800px;
        height: 320px;
        animation: linear infinite normal;
        animation-name: runLeft;
        animation-duration: 14s;
    }
    .cloud-bottom-left {
        // bottom: -38px;
        bottom: -130px;
        left: 76px;
        position: absolute;
        width: 520px;
        height: 342px;
        transform: scaleX(-1) rotate(-90deg);
        z-index: 11;
    }
    .cloud-bottom {
        // bottom: -46px;
        bottom: -86px;
        left: 76px;
        position: absolute;
        width: 835px;
        height: 360px;
        z-index: 11;
    }
    .cloud-bottom-right {
        // bottom: -50px;
        bottom: -188px;
        left: 490px;
        position: absolute;
        width: 531px;
        height: 360px;
        transform: scaleX(-1) scaleY(-1) rotate(-90deg);
        z-index: 11;
    }
}

@keyframes runLeft {
    // 0% {
    //     left: -100px;
    // }
    // 50% {
    //     left: -50px;
    // }
    // 100% {
    //     left: 0;
    //     webkit-transform: scaleX(-1);
    // }

    100% {
        left: -1000px;
        webkit-transform: scaleX(-1);
        opacity: 100;
    }
    // 50% {
    //     left: -100px;
    // }
    0% {
        left: 500px;
        opacity: 0;
    }
}

@keyframes runRight {
    // 0% {
    //     left: 350px;
    // }
    // 50% {
    //     left: 400px;
    // }
    // 100% {
    //     left: 446px;
    //     webkit-transform: scaleX(-1);
    // }
    100% {
        left: 0;
        opacity: 100;
    }
    // 50% {
    //     left: 400px;
    // }
    0% {
        left: 446px;
        webkit-transform: scaleX(-1);
        opacity: 0;
    }
}
