.container,
.default-video-container {
    position: relative;
    height: 100%;
    // iframe {
    //     pointer-events: none;
    // }
    .container-bac {
        width: 100%;
        height: 100vh;
    }
    .bottom-wrap {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        .go-icon,
        .go-icon-disabled {
            width: 160px;
            height: 160px;
            cursor: pointer;
        }
        .go-icon:active {
            transform: scale(1.2);
        }
    }
}
.default-video-container {
    background-image: url('../assets/img/default-video-bac.png');
    background-position: left top;
    background-size: cover;
    overflow: hidden;
    height: 100vh;
}
